<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogSalesDetails"
			transition="dialog-top-transition"
			persistent
			width="900"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
            <v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                    <v-col 
                        cols="6"
                    >
                        Job Details
                    </v-col>
                    <v-col 
                        cols="6"
                        style="text-align: right;"
                    >
                        <v-chip
                            :color="getStatusColor(serviceRequest.status)"
                            outlined
                            dark
                            style="font-size: 14px !important;"
                        >
                            {{ serviceRequest.statusDescription }}
                        </v-chip>
                    </v-col>
                </v-card-title>

                <v-card-text style="margin-top: 20px;">

                    <v-row>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                            style="padding-bottom: 15px;"
                        >
                            <label>Appointment Details</label>                                
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                            style="display: flex; align-items: center; padding-bottom: 15px;"
                        >
                            <div style="display: flex; flex-direction: column; line-height: 30px;">
                                <label v-if="serviceRequest.userResponsibleSelected != null && serviceRequest.userResponsibleSelected != undefined" style="font-size: 20px !important; color: var(--color__main); margin-right: 10px;"> {{ serviceRequest.userResponsibleSelected.name }} </label>
                                <label style="font-size: 20px !important; color: var(--color__main); margin-right: 10px;"> {{ appointmentDate }} </label>
                            </div>

                            <v-tooltip 
                                left 
                                v-if="showEditAppointment"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        v-if="showEditAppointment"
                                        class="btnActions"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showDialogAppointment = true">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit Appointment</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" style="font-size: 15px;" v-bind:href="'https://maps.google.com/?q=' + googleMapsAddress" target="_blank">{{ googleMapsAddress }}</a>
                                </template>
                                <span>Go to maps</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                            style="padding-bottom: 15px;"
                        >
                            &nbsp;
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Created By</label>
                            <v-text-field
                                v-model="serviceRequest.userNameRegister"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Created On</label>
                            <v-text-field
                                v-model="serviceRequest.dateRegisterFormatted"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Expire On</label>
                            <v-text-field
                                v-model="serviceRequest.dateExpireFormatted"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        
                        <v-col 
                            v-if="isLoaded"
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Customer</label>
                            <v-text-field
                                v-model="serviceRequest.customerSelected.name"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            v-if="isLoaded"
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Address</label>
                            <v-text-field
                                v-model="serviceRequest.addressSelected.address"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>

                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Phone</label>
                            <v-text-field
                                v-model="serviceRequest.phone"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>

                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Email</label>
                            <v-text-field
                                v-model="serviceRequest.email"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Estimated Buget</label>
                            <v-text-field
                                v-model="serviceRequest.estimatedBudgetFormatted"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Estimated Start Date</label>
                            <v-text-field
                                v-model="serviceRequest.estimatedDateFormatted"
                                readonly
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            v-if="isLoaded"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Estimated Value - Zillow</label>
                            <br />
                            <label 
                                style="font-size: 30px !important;line-height: 45px; color: var(--color__main) !important"
                            >
                                {{ estimatedValueZillow }}
                            </label>
                            <zillowTerms :address="serviceRequest.addressSelected.address" :lgWidthTerms="'6'" :lgWidthLogo="'6'" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            style="margin-top: 10px;"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Notes</label>
                            <v-textarea
                                v-model="serviceRequest.notes"
                                single-line
                                readonly
                                rows="2"
                                row-height="20"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions 
                    class="justify-center"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2 dialogButtonDismiss v-btn-large"
                                v-on="on"
                                @click="dismiss"
                            >
                                Dismiss
                            </v-btn>
                        </template>
                        <span>Dismiss</span>
                    </v-tooltip>
                    <v-tooltip 
                        v-if="showButtonsAcceptOrDeny"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                v-if="showButtonsAcceptOrDeny"
                                class="mx-2 v-btn-large"
                                v-on="on"
                                style="background-color: var(--color__red) !important; color: #ffffff !important"
                                @click="showDialogDeniedService = true"
                            >
                                Deny
                            </v-btn>
                        </template>
                        <span>Deny the service</span>
                    </v-tooltip>
                    <v-tooltip 
                        v-if="showButtonsAcceptOrDeny"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-if="showButtonsAcceptOrDeny"
                                class="mx-2 v-btn-large"
                                v-on="on"
                                :loading="loading"
                                @click="confirmDecision('ACCEPT')"
                            >
                                Accept
                            </v-btn>
                        </template>
                        <span>Accept Job</span>
                    </v-tooltip>
                    <v-tooltip 
                        v-if="isAssigned"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-if="isAssigned"
                                class="mx-2 v-btn-large"
                                v-on="on"
                                :loading="loading"
                                @click="confirmDecision('START')"
                            >
                                Start
                            </v-btn>
                        </template>
                        <span>Start the service</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>

		<v-dialog
			v-model="showDialogDeniedService"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Explain your reason
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
					<v-row>

						<v-col cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Reason</label>
                            <v-textarea
                                v-model="descriptionDenied"
                                single-line
                                rows="4"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
						</v-col>
					</v-row>
					

				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="showDialogDeniedService = false"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
							@click="confirmDecision('DENY')"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="showDialogAppointment"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Edit Appointment
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="4"
                            md="12"
                            sm="12"
                        >
                            <v-menu
                                ref="mnuStartDate"
                                v-model="mnuStartDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label>Date</label>
                                    <v-text-field
                                        v-model="startDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        :rules=[validations.required]
                                        @blur="startDate = parseDate(startDateFormatted)"
                                        readonly
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="startDate"
                                    no-title
                                    @input="mnuStartDate = false"
                                    :min="today"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        
                        <v-col 
                            cols="12"
                            lg="4"
                            md="6"
                            sm="12"
                        >
                            <label>Start Time</label>
                            <v-text-field
                                v-model="timeStartDisplay"
                                :rules=[validations.required]
                                prepend-inner-icon="mdi-clock-time-four-outline"
                                v-mask="'##:## AA'"
                                outlined
                                single-line
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="4"
                            md="6"
                            sm="12"
                        >
                            <label>End Time</label>
                            <v-text-field
                                v-model="timeEndDisplay"
                                :rules=[validations.required]
                                prepend-inner-icon="mdi-clock-time-four-outline"
                                v-mask="'##:## AA'"
                                outlined
                                single-line
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="showDialogAppointment = false"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
							@click="saveAppointment()"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
	</div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import zillowTerms from '@/components/Zillow/ZillowTerms';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { required } from "@/utilities/Rules";
    import { PROJECT_STATUS } from "@/utilities/Enums";

	export default {

        components: {
            zillowTerms,
            ActionDialog
        },

        mixins: [Helpers],

        props: {

            id: {
                default: 0
            },

            idAux: {
                default: 0
            },

			showDialogSalesDetails: {
				default: false
			},
		},
		
        data: vm => ({

            validForm: true,
			loading: false,

            tab: null,

            estimatedValueZillow: "$ 0,00",
            showDialogDeniedService: false,
            descriptionDenied: "",

            showDialogAppointment: false,
            menuTimeStart: false,
            menuTimeEnd: false,
            AMPMStart: "AM",
			timeStart: null,
			timeStartDisplay: null,
            AMPMEnd: "AM",
			timeEnd: null,
			timeEndDisplay: null,
            today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            startDate: vm.today,
            startDateFormatted: vm.formatDate(vm.today),
            mnuStartDate: false,

			serviceRequest: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
                appointmentCitySelected: {
                    description: "",
                    stateSelected: {
                        code: ""
                    }
                },
                userResponsibleSelected: null
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            validations: {
                required: required
            },

            PROJECT_STATUS,
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
            
            googleMapsAddress() {
                let googleMapsAddress = this.serviceRequest.appointmentAddress;

                if (this.serviceRequest.appointmentCitySelected != null && this.serviceRequest.appointmentCitySelected != undefined) {
                    
                    if (this.serviceRequest.appointmentCitySelected.stateSelected != null && this.serviceRequest.appointmentCitySelected.stateSelected != undefined) {
                        googleMapsAddress += `, ${this.serviceRequest.appointmentCitySelected.stateSelected.code}`;
                    }

                    googleMapsAddress += `, ${this.serviceRequest.appointmentCitySelected.description}`;
                }

                return googleMapsAddress;
            },

            appointmentDate() {
                if (this.serviceRequest != null && 
                    this.serviceRequest != undefined && 
                    this.serviceRequest.startDateFormatted != null && 
                    this.serviceRequest.startDateFormatted != undefined) {
                    return `${this.serviceRequest.startDateFormatted} - ${this.serviceRequest.timeStartFormatted} ${this.serviceRequest.ampmTimeStart} to ${this.serviceRequest.timeCloseFormatted} ${this.serviceRequest.ampmTimeEnd}`;
                }
                return "Loading...";
            },

            isPendingAcceptance() {
                return this.serviceRequest.status == this.PROJECT_STATUS.PENDING_ACCEPTANCE;
            },

            isAssigned() {
                return this.serviceRequest.status == this.PROJECT_STATUS.ASSIGNED;
            },

            isSalesPerson() {
                return this.userLoggedGetters.profile.budgetControl == 1;
            },

            showButtonsAcceptOrDeny() {
                return this.isSalesPerson == true && this.isPendingAcceptance == true;
            },

            showEditAppointment() {
                if (this.isSalesPerson == true) { //sales person
                    return this.serviceRequest.idUserRegister == this.userLoggedGetters.id
                }
                else {
                    return true
                }
            },

            isLoaded() {
                return this.serviceRequest != null &&
                       this.serviceRequest != undefined &&
                       this.serviceRequest.customerSelected != null &&
                       this.serviceRequest.customerSelected != undefined &&
                       this.serviceRequest.addressSelected != null &&
                       this.serviceRequest.addressSelected != undefined
            }
        },

        watch: {

            async "idAux"() {
                await this.getRegister()
            },

            startDate () {
                this.startDateFormatted = this.formatDate(this.startDate)
            },
        },

        methods: {

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
			
            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 0:
                        color = 'var(--color__silver)';
                        break;

                    case 1:
                        color = 'var(--color__red)';
                        break;

                    case 2:
                        color = 'var(--color__main)';
                        break;

                    case 3:
                        color = 'var(--color__status_pending)';
                        break;

                    case 4:
                        color = 'var(--color__completed)';
                        break;

                    case 5:
                        color = 'var(--color__completed)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },
            
            async dismiss() {
                this.$emit('methodConfirmToCall');
                this.$emit('update:showDialogSalesDetails', false);
            },

            async clearFields() {
                this.tab = 0;
                this.serviceRequest = {
                    userNameRegister: "",
                    customerSelected: {
                        name: ""
                    },
                    addressSelected: {
                        address: ""
                    }
                }
            },
            
            async getRegister() {

                await this.clearFields();

                if (this.id !== 0) {
                    const response = await this.$store.dispatch("serviceModule/GetById", this.id);

                    this.serviceRequest = response.result;
                    
                    this.startDate = this.parseDate(this.serviceRequest.startDateFormatted);
                    this.startDateFormatted = this.serviceRequest.startDateFormatted;
                    this.timeStart = this.serviceRequest.timeStartClockFormatted;
                    this.AMPMStart = this.serviceRequest.ampmTimeStart;
                    this.timeStartDisplay = `${this.serviceRequest.timeStartFormatted} ${this.AMPMStart}`;
                    this.timeEnd = this.serviceRequest.timeCloseClockFormatted;
                    this.AMPMEnd = this.serviceRequest.ampmTimeEnd;
                    this.timeEndDisplay = `${this.serviceRequest.timeCloseFormatted} ${this.AMPMEnd}`;

                    this.getEstimatedValueZillow();
                }
            },

            async getEstimatedValueZillow() {

                let zillowRequest = {
                    typeRequest: 2, //Address
                    zipCodeOrAddress: "",
                }

                if (this.serviceRequest.isCustomer === 1) {
                    if (this.serviceRequest.addressSelected != null && this.serviceRequest.addressSelected != undefined) {
                        zillowRequest.zipCodeOrAddress = this.serviceRequest.addressSelected.address;
                    }
                }
                else {
                    zillowRequest.zipCodeOrAddress = this.serviceRequest.address;
                }

                const response = await this.$vanKirkApi.post(`common/zillow`, zillowRequest, {
                        headers: {
                            "content-type": "application/json"
                        }
                    });
                const resultZillow = response.data.result;

                this.estimatedValueZillow = "$ 0,00";
                    
                if (resultZillow && resultZillow.success == true && resultZillow.bundle.length > 0) {
                    this.estimatedValueZillow = resultZillow.bundle[0].zEstimateFormatted;
                }
                else {
                    this.estimatedValueZillow = "Not found"
                }
            },
            
            confirmDecision(type) {

                /*
                    statusId:
                        1 - UNASSIGNED;
                        2 - ACCEPTED;
                        3 - IN PROGRESS;
                */
               
               let statusId = 0;
               switch (type) {
                case 'ACCEPT': statusId = 2; break;
                case 'DENY':   statusId = 1; break;
                case 'START':  statusId = 3; break;
               
                default:
                    break;
               }

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will ${type} this service, confirm your decision?`,
                    methodConfirm: this.confirm,
                    params: statusId,
                };
            },

            async confirm(idStatus) {

                this.showLoading();

                const updateStatusRequest = {
                    id: this.id,
                    status: idStatus,
                    description: this.descriptionDenied
                };

                const result = await this.$store.dispatch("serviceModule/UpdateStatus", updateStatusRequest);

                if (result.success) {
                    this.showToast("success", "Success!", result.message);

                    if (idStatus == 3) {
                        this.$router.push({ name: "scopeOfWork", params: { id: this.id } });
                    }
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.showDialogDeniedService = false;
                this.descriptionDenied = "";
                this.dismiss();

                this.hideLoading();
            },

            async saveAppointment() {

                
                if (!this.timeStartDisplay.toString().includes("AM") && !this.timeStartDisplay.toString().includes("PM")) {
                    this.showToast("error", "Warning!", "Invalid start time!");
                    return false;
                }
                else if (!this.timeEndDisplay.toString().includes("AM") && !this.timeEndDisplay.toString().includes("PM")) {
                    this.showToast("error", "Warning!", "Invalid end time!");
                    return false;
                }
                else {
                    const startDate = this.startDate + " " + this.timeStartDisplay;
                    const closeDate = this.startDate + " " + this.timeEndDisplay;
                    
                    const request = {
                        idService: this.id,
                        appointmentRequest: {
                            startDate,
                            closeDate
                        }
                    };

                    const result = await this.$store.dispatch("serviceModule/UpdateAppointment", request);

                    if (result.success) {
                        this.showToast("success", "Success!", result.message);
                        await this.getRegister();
                        this.showDialogAppointment = false;
                    }
                    else {
                        this.showToast("error", "Error!", result.message);
                    }
                }
            }
        }
    };
</script>